.container {
  position: absolute;
  top: 3rem;
  bottom: 0;
  right: 0;
  width: 50%;
  height: max-content;
  background-color: var(--primary);
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: -10;
  &.active {
    z-index: 100;
    opacity: 1;
    border-radius: 2rem;
  }
  .close-icon {
    position: absolute;
    cursor: pointer;
    content: ' ';
    width: 3.5rem;
    height: 5rem;
    top: -3rem;
    right: 0;
    background-color: var(--primary);
    display: flex;
    padding-top: 0.5rem;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }
  &.active > .close-icon {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }
  .header {
    padding: 2.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    border-bottom: 0.1rem solid #d3d3d3;
    gap: 2rem;
    .title {
      font-size: 1.4rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    .description {
      font-size: 1.4rem;
      padding: 0 2rem;
    }
  }
  .actions {
    padding: 2.5rem 1.5rem;
    display: flex;
    gap: 0.75rem;
    .btn-add-to-cart {
      border: 0.1rem solid #ffffff;
    }
  }
}
