@import '../../../../../components/shares/GlobalStyles/variables';
.container {
  width: 100vw;
  height: 100%;
  .menu-sub {
    position: fixed;
    top: -1rem;
    left: -0.5rem;
    right: 0;
    width: 100vw;
    z-index: 999999;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    padding: 4rem 8%;
    text-align: left;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    z-index: 10;
    overflow-y: auto;

    max-height: calc(100vh - $header-height);
    .menu-sub-lv2 {
      display: flex;
      justify-content: center;
      ul {
        flex-grow: 1;
        color: var(--primary);
        flex-basis: 0%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          color: #868d95;
          line-height: 1.9rem;
          font-size: 1.4rem;
          min-width: 6rem;
          min-height: 1.8rem;
          width: 100%;
          padding: 0.8rem 0;
        }
        li {
          padding: 0;
          font-size: 1.4rem;
          line-height: 1.9rem;
          cursor: pointer;
          color: var(--primary);
          font-weight: 500;
          width: 100%;
          padding: 0 !important;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 0.8rem 0 !important;
            color: var(--primary);
            text-transform: capitalize;
          }
          &:hover > a {
            color: black;
            font-weight: 700;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
