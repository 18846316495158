:root {
  --right-width: 7rem;
  --top-width: 3rem;
  --width-content-modal: 56rem;
  --top-height-modal: 8rem;
}

.modal-content {
  position: fixed;
  top: var(--default-layout-header-height);
  right: 0;
  bottom: 0;
  transform: translateY(-50%, -50%);
  line-height: 1.4;
  background-color: #fff;
  padding: var(--top-width) var(--right-width);
  border-radius: 0.3rem;
  width: var(--width-content-modal);
  z-index: 9999;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  transition: all 0.6s ease-in-out;
  animation: fadeIn 0.6s ease-in-out;
  &.empty {
    overflow-y: none;
    // background-color: red;
  }
  &.no-active {
    animation: fadeOut 0.6s ease-in-out;
  }
  .title {
    margin-bottom: 1rem;
  }
  .close {
    position: absolute;
    top: var(--top-width);
    right: var(--right-width);
    cursor: pointer;
  }
  .content {
    height: calc(100% - var(--top-height-modal));
    overflow-y: scroll;
  }
  .wishlist-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: var(--top-width) var(--right-width);
  }
}

@keyframes fade {
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(40rem);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    transform: translateX(40rem);
    opacity: 0;
  }
}
