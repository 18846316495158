.container {
  width: 100%;
  background-color: var(--white);
  border-bottom-width: 1px;
  z-index: 10;
  transition: all 0.2s linear;
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    animation: slideInTop 0.5s ease-in-out;
  }
  &.out-top {
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    margin-bottom: 9.8rem;
    opacity: 0;
    transform: translateY(-100%);
  }
  @keyframes slideInTop {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .img-banner {
    width: 100%;
  }
  .wrapper {
    width: 100%;
    height: var(--default-layout-header-height);
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    .inner {
      height: 100%;
      width: 100%;
      padding: 0 var(--default-layout-horizontal-spacer);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .nav-left {
        .logo {
          max-width: 30rem;
          cursor: pointer;
        }
      }
      .nav-center {
        ul {
          display: flex;
          align-items: center;
          gap: 1rem;
          li {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.4rem;
            font-weight: 500;
            padding: 1rem;
            line-height: 2rem;
            letter-spacing: 0.1rem;
            color: var(--primary);
            position: relative;
            transition: all 0.4s ease-in-out;
            display: block;

            &:hover {
              color: var(--black);
              font-weight: 600;
            }
            a {
              padding: 1rem;
            }
          }
          .discount {
            font-weight: 600;
            color: var(--red);
            &:hover {
              font-weight: 600;
              color: var(--red);
            }
          }
        }
      }
      .nav-right {
        display: flex;
        align-items: center;
        gap: 2.4rem;
        .search {
          display: flex;
          align-items: center;
          background-color: #f7f8f9;
          margin-right: 5rem;
          padding: 1rem 6.5rem 1rem 2rem;
          border-radius: 2.5rem;
          height: 4rem;
          cursor: pointer;
          span {
            margin-left: 0.8rem;
            letter-spacing: 0.2rem;
            font-size: 1.4rem;
          }
        }
        .profile {
          position: relative;
          .tippy-content {
            font-size: 8rem !important;
            color: var(--primary) !important;
          }
          .user-links {
            display: block;
            width: 100%;
            .user-link {
              font-size: 1.4rem;
              text-align: left;
              font-weight: 400;
              padding: 0.5rem 0;
              border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
              color: rgba(0, 0, 0, 0.7);
              &:first-child {
                color: rgba(0, 0, 0, 1);
              }
              &:last-child {
                border-bottom: none;
              }
              &:hover {
                transition: all 0.2s ease;
                color: rgba(0, 0, 0, 1);
                cursor: pointer;
              }
              a {
                display: block;
                padding-right: 2.5rem;
              }
            }
          }
          .popper-user {
            display: flex;
            flex-direction: column;
            li {
              flex: 1;
              font-size: 1.4rem;
              text-align: left;
              font-weight: 500;
              padding: 0.5rem 0;
              border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
              color: rgba(0, 0, 0, 0.7);
              &:first-child {
                color: rgba(0, 0, 0, 1);
              }
              &:last-child {
                border-bottom: none;
              }
              &:hover {
                transition: all 0.4s ease-in-out;
                color: rgba(0, 0, 0, 1);
                cursor: pointer;
              }
              a {
                display: block;
                padding-right: 2.5rem;
              }
            }
          }
          .buttons {
            width: 100%;
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
          }
        }
        .wishlist {
          position: relative;
        }
        .icons {
          position: relative;
          cursor: pointer;
          border-radius: 50%;
          width: 2.4rem;
          height: 2.4rem;
          &:hover > .icon-active {
            opacity: 1;
          }
          .icon {
            width: 100%;
            height: 100%;
          }
          .icon-active {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            transition: all 0.4s ease;
          }
          .quantity-item {
            position: absolute;
            top: -0.2rem;
            right: -0.4rem;
            font-size: 1rem;
            min-width: 1.5rem;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: var(--primary);
            border: 0.1rem solid transparent;
            padding: 0.2rem;
            color: white;
            transition: all 0.6s ease-in-out;
            &.active {
              color: var(--primary);
              border-color: var(--primary);
              background-color: white;
            }
          }
          &.active > .icon {
            opacity: 0;
          }
          &.active > .icon-active {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .container {
    width: 100%;
    .img-banner {
      display: none;
    }
    .wrapper {
      width: 100%;
      // background-color: var(--red);
      color: white;
      padding: 0 1rem;
      .inner {
        padding: 0;
        .nav-left {
          // display: none;
        }
        .nav-center {
          display: none;
        }
        .nav-right {
          gap: 1.3rem;
          .search {
            display: none;
          }
          .profile {
            display: none;
          }
        }
      }
    }
  }
}
