.tab-header {
  display: flex;
  margin-bottom: 3rem;
  border-bottom: 0.2rem solid #f7f8f9;
  .nav-tabs {
    display: flex;
    .nav-item {
      display: flex;
      text-transform: uppercase;
      text-align: center;
      padding: 0 4.3rem 2.4rem;
      letter-spacing: 0.2rem;
      border-radius: 0.4rem 0.4rem 0 0;
      border-bottom: 0.4rem solid transparent;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      &.active {
        border-color: var(--primary);
      }
    }
  }
}
.tab-content {
}
