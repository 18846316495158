.back-to-top {
  position: fixed;
  bottom: 10rem;
  right: 0;
  transition: all 0.3s ease-in-out;
  z-index: 99;
  display: flex;
  align-items: center;
  .icon {
    cursor: pointer;
    padding: 0;
  }
  &.active {
    // transform: translateY(-50px);
    animation: fadeInTop 0.3s ease-in-out;
  }
  &.not-active {
    opacity: 0;
    // transform: translateY(50px);
    animation: fadeInBottom 0.3s ease-in-out;
  }
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateX(5rem);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInBottom {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(5rem);
  }
}
