.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-banner {
    width: 100%;
  }
}

.container1 {
  // max-width: 100%;
  width: 100%;
  // margin-top: var(--default-layout-header-height);
  display: flex;
}

.content {
  flex: 1;
}
