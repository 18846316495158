.container {
  padding-top: 1rem;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  text-align: center;
  .nav-logo {
    position: absolute;
    top: 1rem;
    left: 8rem;
  }
  .wrapper {
    margin: 0 auto;
    width: var(--default-layout-width);
    .search-header {
      display: flex;
      align-items: center;
      gap: 2rem;
      .nav-input {
        width: 50%;
        text-align: center;
      }
      .nav-close {
        padding: 0.5rem 1rem;
        .close {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          gap: 1rem;
        }
      }
    }
    .search-bar-result {
      padding: 5.5rem 0;
      font-size: 1.4rem;
      // box-shadow: #000000 0px 10px 10px 0px;
      &-wrapper {
        display: flex;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        h5 {
          color: #868d95;
          font-weight: 700;
          margin-bottom: 0.4rem;
          text-transform: uppercase;
          text-align: left;
          font-size: 1.4rem;
        }
      }
      &-left {
        flex: 3;
        .history {
          .list-key-search {
            .item-key-search {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1.6rem 0;
              color: #868d95;
              transition: all 0.3s ease-in-out;
              span {
                cursor: pointer;
              }
              &:not(:last-child) {
                border-bottom: 0.1rem solid #f7f8f9;
              }
              &:hover {
                font-weight: 700;
              }
            }
          }
        }
        .top-search {
          border-top: 0.1rem solid #f7f8f9;
          padding: 3.2rem 0 0 0;
          margin: 1.6rem 0 0 0;
          .active {
            margin-top: 1.6rem;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            span {
              background-color: #f7f8f9;
              border-radius: 0.4rem;
              color: #434343;
              padding: 1rem 1.6rem;
              cursor: pointer;
              transition: all 0.4s ease-in-out;
              &:hover {
                background: #000000;
                color: white;
              }
            }
          }
        }
        .empty-result {
          font-size: 1.2rem;
          color: #8d99a5;
          letter-spacing: 0.1rem;
        }
      }
      &-right {
        flex: 4;
        padding-left: 8rem;
        .search-bar-product-item {
          margin-top: 4rem;
          display: flex;
          flex-direction: column;
          max-height: 55rem;
          overflow-y: scroll;
          gap: 2rem;
          .product-loop-suggest {
            display: flex;
            align-items: center;
            gap: 1rem;
            .product-img {
              width: 10rem;
              height: 12.4rem;
            }
            .product-info {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              font-size: 1.2rem;
              text-align: left;
              .product-brand {
                font-weight: 700;
              }
              .product-price {
                .price {
                  font-size: 1.2rem;
                  font-weight: 700;
                  margin-right: 0.5rem;
                }
                del {
                  font-size: 1.2rem;
                  margin-left: 0.5rem;
                  display: none;
                }
                &.hasSale > .price {
                  background-color: #faff19;
                  font-size: 1.4rem;
                  padding: 0.5rem 1rem;
                }
                &.hasSale > del {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
