.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16rem;
  background-color: var(--primary);
  .container {
    color: #777777;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0rem 3.5rem 1rem;
    .footer-main_left {
      width: 100%;
      display: flex;
      color: #ffffff;
      justify-content: center;
      .col-3 {
        height: 100%;
        flex-basis: 25%;
        .footer-main__menu-title {
          font-size: 1.2rem;
          font-weight: 700;
          letter-spacing: 0.3rem;
          margin: 0 0 1rem;
          text-transform: uppercase;
        }
        .footer-main__menu-links {
          li {
            display: list-item;
            font-size: 1.4rem;
            letter-spacing: 0.1rem;
            text-align: left;
            line-height: 20.3px;
            padding: 1rem 0;
            a {
              color: #ffffff;
              letter-spacing: 0.2rem;
              font-size: 1.2rem;
              line-height: 1.7rem;
              margin: 0 0 2.5rem;
              text-align: left;
              img {
                width: 15rem;
                height: 5.7rem;
              }
            }
          }
        }
        &:last-child {
          flex-basis: 12%;
        }
      }
    }
    .footer-main_copy-right {
      color: #c4c4c4;
      display: flex;
      font-size: 1.2rem;
      flex-direction: column;
      letter-spacing: 0.1rem;
      line-height: 1.5rem;
      text-align: center;
      margin-top: 1rem;
    }
  }
}
.footer-main-right {
  display: none;
}
@media screen and (max-width: 991px) {
  .wrapper {
    padding: 1rem;
    .container {
      padding: 0;
      .footer-main_left {
        flex-wrap: wrap;
        .col-3 {
          // text-align: center;
          margin-top: 1rem;
          &:nth-child(odd) {
            flex-basis: 50% !important;
          }
          &:nth-child(even) {
            flex-basis: 42% !important;
          }
          .footer-main__menu-title {
          }
          .footer-main__menu-links {
            li {
              // text-align: center;
              a {
                font-size: 1.2rem;
                img {
                }
              }
            }
          }
        }
      }
      .footer-main_copy-right {
        display: none;
      }
    }
  }
  .footer-main-right {
    display: flex;
    font-size: 1.4rem;
    color: var(--primary);
    background-color: rgb(31, 29, 29);
    padding: 2.5rem 0 3rem;
    width: 100%;
    flex-direction: column;
    h4 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 0.3rem;
      text-align: center;
      margin: 0 0 2rem;
    }
    .download-app {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      svg {
        cursor: pointer;
      }
    }
  }
}
