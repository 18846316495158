.newsletter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}
.newsletter-footer {
  background-color: #f7f8f9;
  color: var(--primary);
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  padding: 4rem 0;
  line-height: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  &_form {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    width: var(--default-layout-width);
    gap: 7rem;
    &_left {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .newsletter-footer_form_left_gender {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 2.5rem;
        text-align: center;
        .checkbox:first-child {
          margin-right: 5rem;
        }
      }
      p {
        font-weight: 300;
        margin: 1rem 3.5rem 3rem;
        text-align: center;
      }
      span {
        font-size: 3.2rem;
        letter-spacing: 0.4rem;
        line-height: 3.8rem;
        text-align: center;
      }
    }
    &_right {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      .input {
        width: 100%;
      }
      .btn {
        width: 100%;
        margin-top: 3rem;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .newsletter-footer {
    padding: 6rem 0;
    &_form {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      padding: 0 1.5rem;
      &_left {
        width: 100%;
        text-align: center;
        p {
          margin: 0 0 1rem 0;
          font-size: 1.2rem;
        }
        span {
          // line-height: 38px;
          margin-bottom: 1rem;
          font-size: 2.2rem;
          font-weight: normal;
        }
      }
      &_right {
        width: 100%;
      }
    }
  }
}
