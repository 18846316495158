.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 700;
  min-width: 10rem;
  padding: 1.5rem 1.2rem;
  border-radius: 0.2rem;
  letter-spacing: 0.1rem;
  cursor: pointer;
  text-transform: uppercase;
  background-color: var(--white);
  user-select: none;
  position: relative;
  border: 0.1rem solid transparent;
  + .wrapper {
    margin-left: 0.8rem;
  }
  &.disabled {
    pointer-events: none;
    color: var(--primary);
    background-color: rgb(216, 204, 204);
    opacity: 0.5;
  }
  .icon-abs {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
  }
}

.icon + .title,
.title + .icon {
  margin-left: 0.8rem;
}

.icon {
  display: inline-block;
  width: 2.4rem;
  text-align: center;
}

// Button types
.rounded {
  border-radius: 999px;
  border-color: rgba(22, 24, 25, 0.12);
  box-shadow: 0 0.2rem 0.8rem rgba(0 0 0 / 6%);
  &:hover {
    border-color: rgba(22, 24, 35, 0.2);
    background-color: rgba(22, 24, 25, 0.03);
  }
}

.primary {
  background-color: var(--primary);
  color: var(--white);
  // border-color: var(--primary);
  // &:hover {
  //     border-color: var(--primary);
  //     background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #fe2c55;
  // }
  &.border {
    border-color: var(--white);
  }
}
.border {
  border: 1px solid var(--primary);
  &:hover {
    background-color: var(--primary);
    color: #ffffff;
  }
}
.outline {
  color: var(--primary);
  border-color: currentColor;
  &:hover {
    border-color: currentColor;
    background-color: rgba(254, 44, 85, 0.06);
  }
}

.text {
  &:hover {
    text-decoration: underline;
  }
}
// Disabled
// Button sizes
.small {
  min-width: 8.8rem;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.large {
  min-width: 148px;
  padding: 2rem 1.6rem;
}
