@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,600;0,700;0,800;1,600;1,700&display=swap');
@import url('https://rsms.me/inter/inter.css');
@import './variables';

:root {
  --primary: #2e2e2e;
  --secondary: #fe8ce5;
  --default-layout-header-height: 5.6rem;
  --default-layout-width: 115rem;
  --default-layout-horizontal-spacer: 2.4rem;
  --black: #000;
  --text-color: #161823;
  --white: #fff;
  --red: #ff0000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
  color: var(--primary);
  overflow-y: overlay;
}
@supports (font-variation-settings: normal) {
  body {
    font-family: 'Inter var', sans-serif;
  }
}
// Scrollbar
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 0.8rem;
  height:0.8rem
}

html *::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
}
button {
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
ul {
  list-style: none;
}
img {
  object-fit: cover;
}
// Custom tippy tooltip
body {
  .tippy-box {
    height: 3.8rem;
    border-radius: 0.8rem;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    background-color: rgba(82, 84, 84, 0.92);
  }
  .tippy-arrow {
    color: rgba(82, 84, 84, 0.92);
  }
  .tippy-content {
    padding: 0.8rem 0.9rem;
  }
  .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
    top: -0.8rem;
  }
}

.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.fd-column {
  flex-direction: column;
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.gap-1 {
  gap: 1rem;
}
.d-none {
  display: none !important;
}
.text-center {
  text-align: center;
}
.container-fluid {
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 2.1rem;
  padding: 0 8rem;
  width: 100%;
  max-width : 100vw;
}
.custom-checkbox {
  vertical-align: top;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  margin: 0 5px 0 0;
  cursor: pointer;
  outline: 0 !important;
  &::before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    -moz-transform: rotate(-45deg) scale(0, 0);
    -ms-transform: rotate(-45deg) scale(0, 0);
    -o-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    content: '';
    position: absolute;
    left: 0px;
    right: 0;
    top: 5px;
    margin: auto;
    z-index: 1;
    width: 10px;
    height: 5px;
    border: 1px solid #fff;
    border-top-style: none;
    border-right-style: none;
  }
  &:checked::before {
    -webkit-transform: rotate(-45deg) scale(1, 1);
    -moz-transform: rotate(-45deg) scale(1, 1);
    -ms-transform: rotate(-45deg) scale(1, 1);
    -o-transform: rotate(-45deg) scale(1, 1);
    transform: rotate(-45deg) scale(1, 1);
  }
  &::after {
    border-color: #c6cbd0;
    content: '';
    position: absolute;
    left: 0px;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 1px solid #c6cbd0;
    cursor: pointer;
    border-radius: 2px;
  }
  &:checked:after {
    background-color: #2e2e2e;
    border-color: transparent;
  }
}
.custom-radio {
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  margin: 0 5px 0 0;
  cursor: pointer;
  outline: 0 !important;
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #FFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  }
  &:checked::before {
    transform: translate(-50%, -50%) scale(1);
    background-color: #2e2e2e;
  }
  &::after {
    border-color: #c6cbd0;
    content: '';
    position: absolute;
    left: 0px;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 1px solid #c6cbd0;
    cursor: pointer;
    border-radius: 50%;
    
  }
  &:checked:after {
    // background-color: #2e2e2e;
    border-color: #2e2e2e;
  }
}
svg {
  vertical-align: bottom;
}
select {
  outline: none;
  letter-spacing: 0.1rem;
  border-radius: 0.2rem;
  border: 0.1rem solid #868d95;

  font-size: 1.4rem;
  &:focus {
    box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.5);
  }
}
.p10 {
  padding: 1rem 0;
}

@media screen and (max-width: 600px) {
  .container-fluid {
    padding: 0 1rem; /* Adjust padding for mobile */
  }
}

/* Laptop: width <= 992px */
@media screen and (min-width: 601px) and (max-width: 992px) {
  .container-fluid {
    padding: 0 2rem; /* Adjust padding for laptop */
  }
}

/* PC: width > 992px */
@media screen and (min-width: 993px) {
  .container-fluid {
    padding: 0 8rem; /* Default padding for PC */
  }
}

// CSS BUTTON ARROW
.btn-arrow {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.4);
  transition: all 1s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
  }
  &.btn-prev {
    left: 6rem;
    &.center {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.btn-next {
    right: 6rem;
    &.center {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.message-error {
  font-size: 1.2rem;
  color: $error;
}

.p-0-i {
  padding: 0 !important;
}
.p-0-content-box {
  padding: 0 !important;
}
.ant-collapse-content-box {
  padding: 0 !important;
}
:where(.css-dev-only-do-not-override-gzal6t).p-0-small >.p-0-item >.p-0-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-collapse-borderless >.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-gzal6t).ant-collapse-borderless >.ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

// ** Override the css input
:where(.css-dev-only-do-not-override-1ex2vr).ant-input:focus, :where(.css-dev-only-do-not-override-1ex2vr).ant-input:focus-within {
  border-color: #2e2e2e;
  box-shadow: none;
  outline: 0;
}
:where(.css-dev-only-do-not-override-1ex2vr).ant-input {
  border-radius: 0;
}
.ant-select-selector {
  border-radius: 0 !important;
  box-shadow: none !important;
}

// ** Override the css button
:where(.css-dev-only-do-not-override-1ex2vr).ant-btn {
  border-radius: 0 !important;
}
button.ant-btn-primary {
  background-color: #2e2e2e;
}
.bg-primary-gradient {

  background: linear-gradient(72.47deg,#2e2e2e 22.16%, #2e2e2eda   76.47%)!important
}

:where(.css-dev-only-do-not-override-1ex2vr).ant-btn-primary {
  box-shadow: none;
}