.container {
  position: relative;
  display: flex;
  width: 100%;
  .search-icon {
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .search-input {
    width: 100%;
    background-color: #ffffff;
    border: 0.1rem solid #d8d8d8;
    border-radius: 3rem;
    font-size: 1.4rem;
    padding: 1rem 2rem 1rem 4.5rem;
  }
  .loading-icon {
    right: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
