.product-recommended {
  .line {
    background-color: #f7f8f9;
    width: 100%;
    height: 0.4rem;
    margin-bottom: 2rem;
  }
}
.container-slide {
  width: 50rem !important;
  max-width: 100%;
  min-width: 100%;
  padding: 0 !important;
  height: max-content;
  &.mr-0 > div {
    margin: 0 !important;
  }
}
