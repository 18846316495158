.container {
  background-color: white;
  height: 100%;
  width: 100%;
  .wrapper {
    display: flex;
    flex-direction: column;
    &:hover > .product-img > .outer-product-color {
      opacity: 1;
      transform: translateY(0);
      z-index: 1;
    }
    .product-img {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      cursor: pointer;
      .img-link {
        display: flex;
        transition: all 0.5s ease-in-out;
        position: relative;
        // min-height: 46rem;
        img {
          width: 100%;
          height: 100%;
        }
        .lazyloaded {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 0;
          transition: all 0.5s ease-in-out;
          &.is-cached {
            opacity: 1;
          }
        }
      }

      .discount {
        position: absolute;
        top: 1.4rem;
        left: 0;
        background-color: #000000;
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 0.3rem 0.6rem;
        cursor: default;
      }
      .wishlist {
        position: absolute;
        top: 1.4rem;
        right: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        &.active > .heart {
          display: none;
        }
        &.active > .heart-active {
          display: block;
        }
        .heart,
        .heart-active {
          transition: all 2s ease-in-out;
        }
        .heart-active {
          display: none;
        }
      }
      .banner-product {
        width: 100%;
        height: 100%;
        display: flex;
        .banner-img {
          width: 100%;
          height: 100%;
        }
      }
      .outer-product-color {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        min-height: 5.6rem; // current 48px
        height: 15%;
        background-color: rgba(255, 255, 255, 0.4);
        transform: translateY(100%);
        transition: all 0.3s ease-in-out;
        display: flex;
        padding: 0.8rem;
        gap: 0.8rem;
        .product-color-loop {
          display: flex;
          border: 0.1rem solid transparent;
          border-radius: 0.6rem;
          height: 100%;
          flex-basis: calc(16.666667% - 0.8rem);
          overflow: hidden;
          cursor: pointer;
          .product-color-img {
            width: 100%;
            height: 100%;
          }
          &:hover {
            border-color: var(--primary);
          }
          &.active {
            border-color: var(--primary);
          }
        }
      }
    }
    .product-loop-info {
      padding: 0.5rem 1.5rem;
      .trademark-name {
        font-weight: 700;
        line-height: 2.4rem;
        margin: 1rem 0;
        text-transform: uppercase;
        cursor: pointer;
      }
      .product-name {
        min-height: 4.8rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        cursor: pointer;
        overflow: hidden;
      }
      .quantity-color {
        margin-bottom: 2rem;
        display: block;
      }
      .product-price {
        .price {
          font-weight: 400;
          line-height: 2.4rem;
          padding: 0.5rem 0.5rem 0.5rem 0;
          &.hasSale {
            background-color: #faff19;
          }
        }
        // del {
        //   line-height: 1.4rem;
        //   margin-left: 1.2rem;
        // }
      }
    }

    .td-underline {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1400px) {
  // .container {
  //   .wrapper {
  //     &:hover > .product-img > .outer-product-color {
  //     }
  //     .product-img {
  //       .img-link {
  //         // min-height: 328px;
  //       }
  //     }
  //   }
  // }
}
@media screen and (max-width: 991px) {
  // .container {
  //   .wrapper {
  //     &:hover > .product-img > .outer-product-color {
  //     }
  //     .product-img {
  //       .img-link {
  //         height: 180px;
  //         width: 100%;
  //         min-height: 150px;
  //       }
  //     }
  //   }
  // }
}
