.container {
  margin-bottom: 2rem;
  display: flex;
  position: relative;
  input[type='checkbox'] {
    margin-right: 1.5rem;
    &:checked {
      border: none;
      outline: 0.2rem solid var(--primary);
    }
  }
  input:checked {
    outline: 0.2rem solid var(--primary);
    border: none;
  }
  .image-wrapper {
    img {
      width: 10rem;
      height: 14rem;
      object-fit: cover;
    }
  }
  .text-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .brand {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-size: 1.2rem;
      }
      .close {
        letter-spacing: 0.1rem;
        font-size: 1.4rem;
        padding: 0.2rem 0;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: relative;
      }
    }
    .product-name {
      font-weight: 400;
      margin-bottom: 1rem;
      letter-spacing: 0.1rem;
      font-size: 1.4rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      &.item-cart {
        -webkit-line-clamp: 1;
        font-size: 1.2rem;
      }
    }
    .product-price {
      display: flex;
      align-items: center;
      letter-spacing: 0.1rem;
      .price {
        font-size: 1.2rem;
        font-weight: 700;
        margin-right: 0.5rem;
      }
      del {
        font-size: 1.2rem;
        margin-left: 0.5rem;
        display: none;
      }
      &.hasSale > .price {
        background-color: #faff19;
        font-size: 1.4rem;
        padding: 0.5rem 1rem;
      }
      &.hasSale > del {
        display: block;
      }
    }
    .variant-select {
      display: flex;
      align-items: flex-start;
      font-size: 1.2rem;
      justify-content: space-between;
      letter-spacing: 0.1rem;
      padding-top: 0;
      .product-variant-color {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;
        margin: 1rem 0 0;
      }
      .product-variant-size {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;
        margin: 1rem 0 0;
        select {
          width: 100%;
          padding: 0.5rem;
          font-size: 1.2rem;
          border: 0.1rem solid #767676;
          border-radius: 0.2rem;
        }
      }
    }
    .product-actions {
      font-size: 1.2rem;
      margin-top: 1rem;
    }
    .product-quantity {
      margin-top: 0.5rem;
      display: flex;
      .input-number {
        display: flex;
        align-items: center;
        border: 0.1rem solid #868d95;
        border-radius: 0.2rem;
        min-width: 4.2rem;
        height: 4rem;
        .btn-quantity {
          width: 4rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: #fff;
        }
        .input-quantity {
          width: 2.5rem;
          max-height: 4rem;
          font-size: 1.2rem;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}
