$primary: #2e2e2e;
$secondary: #fe8ce5;
$default-layout-header-height: 5.6rem;
$default-layout-width: 115rem;
$default-layout-horizontal-spacer: 2.4rem;
$black: #000;
$text-color: #161823;
$white: #fff;
$red: #ff0000;
$background-color-light: #ffffff;

$header-height: 5.6rem;

$border-outline-color: #e8e8e8d8;

$error: #e24c4c;
$line-border: 0.1rem solid #868d95;
$color-hover: rgb(253, 135, 8);