.social-footer {
  color: var(--primary);
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  padding: 2rem 0;
  width: 100%;
  .container-fluid {
    padding: 0 8rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .social-links {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3rem;
        .line {
          width: 0.2rem;
          height: 2rem;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.3);
        }
        a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .social-footer {
    .container-fluid {
      justify-content: center;
      width: 100%;
      padding: 0 0.4rem;
      .logo {
        display: none;
      }
      .social-links {
        width: 100%;
        li {
          margin-right: 1.5rem;
          .line {
          }
          a {
          }
        }
      }
    }
  }
}
