@import '../../../../../components/shares/GlobalStyles/variables';
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.modal-menu {
  width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 100;
  padding: 1rem;
  animation: fadeInLeft 0.6s ease-out;
  &.closed {
    animation: fadeOutLeft 0.6s ease-in-out;
  }
  background-color: red;
  color: $text-color;
  .modal-content {
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .modal-logo {
        width: 200px;
      }
      .modal-icon {
        font-size: 2rem;
        display: flex;
      }
    }
    .modal-body {
      display: flex;
      position: relative;
      overflow: hidden;
      margin-top: 1rem;
      .content {
        flex: 1;
        transition: all 0.3s ease-out;
        .search-input {
          position: relative;
          input {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            height: 50px;
            background-color: transparent;
            line-height: 28px;
            padding: 0 15px;
            border: 1px solid transparent;
            border-radius: 6px;
            box-shadow: 0 13px 14px 0 rgba(129, 104, 145, 0.05);
            transition: all 0.3s ease-out;
            padding-right: 4rem;
            color: currentColor;
            &:focus {
              border-color: $primary;
            }
          }
          .search-btn {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 5rem;
            font-size: 2.2rem;
            background-color: transparent;
            color: currentColor;
            // color: $text-body-light;
          }
        }
        .link {
          display: flex;
          margin: 1rem 0;
          .nav-link {
            display: flex;
            align-items: center;
            width: 100%;
            border-radius: 6px;
            &__title {
              flex: 1;
            }
            &:hover {
              background-color: $primary;
            }
            &:hover .nav-link__icons > .icon.active {
              opacity: 1;
            }
            &.active {
              background-color: $primary;
              color: $white;
            }
            &.active .nav-link__icons > .icon.active {
              opacity: 1;
            }
            &__icons {
              font-size: 1.6rem;
              width: 5rem;
              height: 5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
            }
            &.setting {
              border-radius: 0;
              border-top: 1px solid rgba($color: $border-outline-color, $alpha: 0.3);
            }
          }
        }
        .social-share {
          margin-top: 1rem;
          h3 {
            text-transform: uppercase;
            padding-bottom: 14px;
            font-weight: 600;
            letter-spacing: 0.5px;
            padding: 1rem;
            opacity: 0.5;
            margin-bottom: 6px;
            border-bottom: 1px solid $border-outline-color;
          }
          .social-icon {
            display: flex;
            padding: 1rem;
            gap: 1rem;
            margin: 2rem 0;
            li {
              border-radius: 50%;
              border: 1px solid $primary;
              padding: 10px;
              cursor: pointer;
              transition: 0.6s;
              &:hover {
                background-color: $primary;
                color: white;
              }
              span {
                display: flex;
                font-size: 2rem;
              }
            }
          }
        }
      }
      .content.closed {
        transform: translateX(calc(-100% - 1rem));
      }
      .content-setting {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-out;
        transform: translateX(100%);
        padding: 1rem;
        .switch {
          label {
            cursor: pointer;
          }
          input[type='checkbox'] {
            // opacity: 0;
            display: none;
          }
          .level {
            content: '';
            display: inline-block;
            position: relative;
            width: 36px;
            height: 14px;
            background: rgba(0, 0, 0, 0.38);
            border-radius: 15px;
            margin-right: 10px;
            transition: all 0.3s ease;
            vertical-align: middle;
            margin: 0 16px;
            &:before,
            &:after {
              content: '';
              position: absolute;
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              left: 0;
              top: -3px;
              transition: left 0.3s ease, all 0.3s ease, box-shadow 0.1s ease, transform 0.1s ease;
            }
            &:after {
              background-color: #eeeeee;
              box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 1px 5px 0 rgba(0, 0, 0, 0.12);
            }
            &::before {
              background-color: #ffecb3;
            }
          }
          input[type='checkbox']:checked + .level::before,
          input[type='checkbox']:checked + .level::after {
            left: 1.8rem;
            background-color: $primary;
          }
        }
        &__go-back {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin: 1rem 0;
        }
        &__title {
          padding: 1rem 0;
          border-bottom: 1px solid rgba($color: $border-outline-color, $alpha: 0.3);
        }
        &__list {
          padding: 1rem 0;
        }
        &__item {
          padding: 1rem 0;
        }
      }
      .content-setting.open {
        transform: translateX(0%);
      }
    }
  }
}
