@import '@/components/shares/GlobalStyles/variables';
.input-container {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  // margin-top: 1.6rem;
  // width: 100%;
  // margin-top: 1.6rem;
  .input-label {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    line-height: 1.45rem;
    margin: 0 0 0.8rem;
    text-transform: uppercase;
  }
  .input {
    position: relative;
    input {
      width: 100%;
      border: $line-border;
      border-radius: 0.2rem;
      background-color: #ffffff;
      font-size: 1.4rem;
      line-height: 1.4rem;
      transition: all 0.2s ease-in-out;
      padding: 1.2rem 1.5rem;
      letter-spacing: 0.2rem;
      &:placeholder-shown {
        // text-transform: capitalize;
      }
      &:focus {
        box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.5);
      }
      &:disabled {
        background-color: #f7f8f9;
      }
    }
    .icon-eye {
      display: none;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.disabled {
        cursor: text;
        opacity: 0.4;
      }
    }
    
    &.error > input {
      transition: all 0.2s ease-in-out;
      border-color: $error;
      box-shadow: 0 0 0.4rem 0 $error;
    }
  }

}

.checkbox {
  display: flex;
  align-items: center;
  label {
    font-family: Helvetica;
    letter-spacing: 0.1rem;
    margin: 0;
  }
  
}
